<template>
    <div class="edit">
        <div class="edit-top">
            <div class="edit-top-left">
                <input-unit class="edit-top-left-logo" titleKey="机构睡眠报告抬头名称" subtitleKey="机构睡眠报告副标题"
                    :initalTitleValue="reportTitle" :initalSubtitleValue="reportSubtitle" iconKey="睡眠报告Logo"
                    :initialIconURL="reportLogo" @onInputInput="onReportInputInput"
                    @onSubInputInput="onReportSubInputInput" @onUploadSucceed="onReportLogoUploadSucceed"></input-unit>
            </div>
            <div class="edit-top-right">
                <div class="edit-top-right-trend">
                    <p class="edit-top-right-trend-title">趋势图显示配置</p>
                    <div class="edit-top-right-trend-blood-oxygen">
                        <p class="edit-top-right-trend-blood-oxygen-title">血氧/脉率聚合显示：</p>
                        <el-switch class="edit-top-right-trend-blood-oxygen-switch"
                            v-model="isCombineBloodOxygenAndPulse" active-color="#6DA4D8" inactive-color="#DCDFE6">
                        </el-switch>
                    </div>
                    <div class="edit-top-right-trend-installment">
                        <p class="edit-top-right-trend-installment-title">
                            睡眠分期/呼吸事件聚合显示：
                        </p>
                        <el-switch class="edit-top-right-trend-installment-switch" v-model="isCombinePeriodAndEvent"
                            active-color="#6DA4D8" inactive-color="#DCDFE6">
                        </el-switch>
                    </div>


                </div>
                <div class="edit-top-right-invalid">
                    <p class="edit-top-right-invalid-title">无效报告配置</p>
                    <div class="edit-top-right-invalid-blood-oxygen">
                        <p class="edit-top-right-invalid-blood-oxygen-title">开放缺少睡眠初筛报告查看：</p>
                        <el-switch class="edit-top-right-invalid-blood-oxygen-switch" v-model="isOxygenDeficiencyView"
                            active-color="#6DA4D8" inactive-color="#DCDFE6">
                        </el-switch>
                    </div>
                </div>

            </div>

        </div>
        <div class="edit-line"></div>
        <div class="edit-bottom">
            <pick-unit titleKey="儿童报告诊断标准" :width="286" :initialPickedIndex="currentPickedStandardIndex"
                @onListItemClick="onStandardListItemClick"></pick-unit>
            <pick-unit class="edit-bottom-right" titleKey="报告评估建议" :initialPickedIndex="currentPickedSuggestionIndex"
                @onListItemClick="onSuggestionListItemClick"></pick-unit>
        </div>
        <div class="edit-buttons">
            <div class="edit-buttons-container">
                <button class="edit-buttons-container-cancel" @click="onCancelClick">取消</button>
                <button class="edit-buttons-container-save" @click="onSaveClick">保存</button>
            </div>
        </div>
    </div>
</template>

<script>
import InputUnit from "@c/setting/organization-configuration/edit/input-unit.vue";
import PickUnit from "@c/setting/organization-configuration/edit/pick-unit.vue";
export default {
    components: {
        InputUnit,
        PickUnit,
    },
    props: {
        model: Object
    },
    data() {
        return {
            isCombineBloodOxygenAndPulse: false,
            isCombinePeriodAndEvent: false,
            isOxygenDeficiencyView: false,
            iconURL: require("@imgs/logo-placeholder.png"),
            currentPickedStandardIndex: 0,
            currentPickedSuggestionIndex: 0,
            hospitalTitle: "",
            hospitalLogo: "",
            reportTitle: "",
            reportSubtitle: "",
            reportLogo: "",
        };
    },

    computed: {

        initialReportTitle() {
            return this.model.reportTitle;
        },
        initialReportSubtitle() {
            return this.model.reportSubtitle;
        },

        initialReportLogo() {
            if (this.model.reportLogo == undefined) {
                return require("@imgs/logo-placeholder.png");
            } else {
                return this.model.reportLogo;
            }
        },

        initialPickedStandardIndex() {
            return getObjectValues(CHILD_STANDDARD_TYPE_CODE).indexOf(
                this.model.reportStandard
            );
        },

        initialPickedSuggestionIndex() {
            return getObjectValues(REPORT_SUGGESTION_TYPE).indexOf(
                this.model.reportEvaluate
            );
        },

        standDartIndex() {
            return getObjectValues(CHILD_STANDDARD_TYPE_CODE)[
                this.currentPickedStandardIndex
            ];
        },

        suggestionIndex() {
            return getObjectValues(REPORT_SUGGESTION_TYPE)[
                this.currentPickedSuggestionIndex
            ];
        },
    },

    created() {
        this.initVulue()
        this.reportTitle = this.initialReportTitle;
        this.reportSubtitle = this.initialReportSubtitle;
        this.reportLogo = this.initialReportLogo;
        this.currentPickedStandardIndex = this.initialPickedStandardIndex;
        this.currentPickedSuggestionIndex = this.initialPickedSuggestionIndex;
    },




    methods: {

        initVulue() {
            if (this.model.bloodPulse == "1") {
                this.isCombineBloodOxygenAndPulse = true
            } else {
                this.isCombineBloodOxygenAndPulse = false
            }

            if (this.model.stageEvent == "1") {
                this.isCombinePeriodAndEvent = true
            } else {
                this.isCombinePeriodAndEvent = false
            }

            if (this.model.oxygenDeficiencyView == "1") {
                this.isOxygenDeficiencyView = true
            } else {
                this.isOxygenDeficiencyView = false
            }
        },
        // action
        onCancelClick() {
            this.$emit("onCancelClick");
        },
        onSaveClick() {
            if (!this.reportTitle) {
                this.$toast.showRed("报告抬头不能为空")
                return
            }
            const settingInfoModel = {
                bloodPulse: this.isCombineBloodOxygenAndPulse ? "1" : "0",
                stageEvent: this.isCombinePeriodAndEvent ? "1" : "0",
                oxygenDeficiencyView: this.isOxygenDeficiencyView ? "1" : "0",
                reportLogo: this.reportLogo,
                reportTitle: this.reportTitle,
                reportSubtitle: this.reportSubtitle,
                reportLogo: this.reportLogo,
                standDartIndex: this.standDartIndex,
                suggestionIndex: this.suggestionIndex,
            };
            this.$emit("onSaveClick", settingInfoModel);
        },

        onCompanyInputInput(value) {
            this.hospitalTitle = value;
        },

        onReportInputInput(value) {
            this.reportTitle = value;
        },
        onReportSubInputInput(value) {
            this.reportSubtitle = value;
        },
        onStandardListItemClick(selectedIndex) {
            this.currentPickedStandardIndex = selectedIndex;
        },

        onSuggestionListItemClick(selectedIndex) {
            this.currentPickedSuggestionIndex = selectedIndex;
        },
        onCompanyLogoUploadSucceed(iconURL) {
            this.hospitalLogo = iconURL;
        },

        onReportLogoUploadSucceed(iconURL) {
            this.reportLogo = iconURL;
        },
    },
};
</script>


<style lang="scss" scoped>
.edit {
    background-color: white;
    width: 100%;
    position: relative;
    margin-top: 20px;

    &-top {
        display: flex;

        &-right {
            position: absolute;
            left: rem(618);

            &-trend {
                &-title {
                    color: black;
                    font-size: rem(16);
                    font-weight: bold;
                    height: rem(22);
                    margin-top: rem(16);
                }

                &-blood-oxygen {
                    display: flex;
                    align-items: center;
                    height: rem(26);
                    margin-top: rem(15);
                    font-size: rem(14);
                    color: black;

                    &-title {
                        width: rem(199);
                    }

                    &-switch {
                        transform: scale(1.3);
                        margin-left: rem(6);
                    }
                }

                &-installment {
                    display: flex;
                    align-items: center;
                    height: rem(26);
                    margin-top: rem(15);
                    font-size: rem(14);
                    color: black;

                    &-title {
                        width: rem(199);
                    }

                    &-switch {
                        transform: scale(1.3);
                        margin-left: rem(6);
                    }
                }


            }

            &-invalid {
                &-title {
                    color: black;
                    font-size: rem(16);
                    font-weight: bold;
                    height: rem(22);
                    margin-top: rem(28);
                }

                &-blood-oxygen {
                    display: flex;
                    align-items: center;
                    height: rem(26);
                    margin-top: rem(15);
                    font-size: rem(14);
                    color: black;

                    &-title {
                        width: rem(199);
                    }

                    &-switch {
                        transform: scale(1.3);
                        margin-left: rem(6);
                    }
                }
            }
        }
    }

    &-line {
        background-color: #e8e8e8;
        width: 100%;
        height: rem(1);
        margin-top: rem(65);
    }

    &-bottom {
        margin-top: rem(35);
        display: flex;

        &-right {
            position: absolute;
            left: rem(618);
        }
    }

    &-buttons {
        width: 100%;
        display: flex;
        justify-content: center;
        margin-top: rem(40);

        &-container {
            display: flex;

            &-cancel {
                color: #325FAC;
                font-size: rem(16);
                border: rem(1) solid #325FAC;
                border-radius: rem(4);
                width: rem(100);
                height: rem(40);
                display: flex;
                align-items: center;
                justify-content: center;
            }

            &-save {
                color: white;
                background-color: #325FAC;
                font-size: rem(16);
                border: rem(1) solid #325FAC;
                border-radius: rem(4);
                width: rem(100);
                height: rem(40);
                display: flex;
                align-items: center;
                justify-content: center;
                margin-left: rem(50);
            }
        }
    }
}
</style>